<template>
  <v-container fluid class="pageContainer">
    <v-row
      v-if="!loading"
      align-content="center"
      justify="center"
      style="min-height: 231px;"
    >
      <v-col cols="12">
        <VueSlickCarousel
          v-bind="carrouselSettings"
          ref="shieldsCarousel"
          :arrows="false"
          :variableWidth="shields.length > 1"
          @afterChange="updateSelectedShield"
        >
          <div
            v-for="(shield, index) in shields"
            :key="shield._id"
            class="shield-container"
          >
            <v-btn
              v-if="previousSelectedIndex === index && index > 0"
              class="arrowLeftIcon"
              icon
              @click="carouselPrevious()"
            >
              <v-icon class="slideLeftAnimation">
                mdi-chevron-left
              </v-icon>
            </v-btn>

            <inline-svg
              :src="`${shield.image}?x-request=xhr`"
              :id="`shield-${index}`"
              class="shield-image"
            />

            <v-btn
              v-if="previousSelectedIndex === index && index < shields.length - 1"
              class="arrowRightIcon"
              icon
              @click="carouselNext()"
            >
              <v-icon class="slideRightAnimation">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </VueSlickCarousel>
      </v-col>

      <v-col cols="12" class="d-flex justify-center pa-0">
        <v-btn text @click="randomShield()" color="secondary" class="text-btn">
          <v-icon class="mr-2" color="secondary">mdi-shuffle-variant</v-icon>
          Aleatório
        </v-btn>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="d-flex flex-column justify-center">
        <p class="text-secondary">Cores</p>
        <v-row v-if="previousSelectedIndex >= 0" justify="center">
          <v-col
            cols="3"
            v-for="(element, index) in shields[previousSelectedIndex].elements"
            :key="`shield-${previousSelectedIndex}-color-picker-${index}`"
          >
            <div class="d-flex flex-column justify-center align-center">
              <span class="mb-1 element-name">
                {{element.name}}
                <v-tooltip v-if="element.description" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{element.description}}</span>
                </v-tooltip>
              </span>
              <v-menu
                v-if="parsedShields[previousSelectedIndex]"
                bottom
                origin="center center"
                transition="scale-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="default-color-picker"
                    :style="`background: ${parsedShields[previousSelectedIndex].colors[index]}`"
                    v-bind="attrs"
                    v-on="on"
                    @click="updateSelectedElement(index)"
                  ></div>
                </template>

                <chrome-picker
                  :value="parsedShields[previousSelectedIndex].colors[index]"
                  :disableFields="true"
                  :disableAlpha="true"
                  @input="updateElementColorValue"
                />
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="11">
        <v-btn class="app-btn" color="primary" x-large block @click="sendSelectedShieldToApp()">
          <span>
            Próximo
          </span>
        </v-btn><br>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import VueSlickCarousel from 'vue-slick-carousel'
import Chrome from 'vue-color/src/components/Chrome.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    VueSlickCarousel,
    InlineSvg,
    'chrome-picker': Chrome
  },
  data () {
    return {
      loading: true,
      changedColor: 0,
      shields: [],
      parsedShields: {},
      loadedDataFromApp: false,
      previousSelectedIndex: -1,
      selectedShieldElement: -1,
      carrouselSettings: {
        infinite: false,
        focusOnSelect: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '0',
        outerEdgeLimit: true
      }
    }
  },
  mounted () {
    this.getShields()
  },
  methods: {
    getShields () {
      this.loading = true

      this.$http.get('/shields')
        .then((res) => {
          const { data } = res.data
          this.shields = data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
          setTimeout(() => {
            this.updateSelectedShield(0)
          }, 500)
        })
    },
    updateSelectedShield (index) {
      if (this.previousSelectedIndex !== index) {
        document.getElementById(`shield-${index}`).classList.remove('unscaleAnimation')
        document.getElementById(`shield-${index}`).classList.add('scaleAnimation')

        if (this.previousSelectedIndex >= 0) {
          document.getElementById(`shield-${this.previousSelectedIndex}`).classList.remove('scaleAnimation')
          document.getElementById(`shield-${this.previousSelectedIndex}`).classList.add('unscaleAnimation')
        }
        this.previousSelectedIndex = index
        this.updateSelectedShieldObj()
      }
    },
    updateSelectedShieldObj (colors = []) {
      var defaultColor = '#BABABA'
      var shield = this.shields[this.previousSelectedIndex]
      if (!this.parsedShields[this.previousSelectedIndex]) {
        var _colors = colors.length > 0 ? colors : Array.from({ length: shield.elements.length }, () => (defaultColor))

        this.parsedShields[this.previousSelectedIndex] = {
          pattern: shield._id,
          colors: _colors
        }

        if (!this.loadedDataFromApp) {
          this.getDataFromApp()
        }
      }
    },
    updateElementColorValue (data) {
      if (this.selectedShieldElement >= 0) {
        this.parsedShields[this.previousSelectedIndex].colors[this.selectedShieldElement] = data.hex

        document
          .getElementById(`shield-${this.previousSelectedIndex}`)
          .getElementById(this.shields[this.previousSelectedIndex].elements[this.selectedShieldElement].id).style.fill = data.hex
      }
    },
    updateSelectedElement (index) {
      this.selectedShieldElement = index
    },
    randomShield () {
      var randomIndex = Math.floor((Math.random() * this.shields.length))
      var randomColors = Array.from({ length: this.shields[randomIndex].elements.length }, () => this.randomColor())
      this.previousSelectedIndex = randomIndex
      this.updateSelectedShieldObj(randomColors)
      for (let elementIndex = 0; elementIndex < this.shields[randomIndex].elements.length; elementIndex++) {
        this.selectedShieldElement = elementIndex
        this.updateElementColorValue({ hex: randomColors[elementIndex] })
      }

      this.$refs.shieldsCarousel.goTo(randomIndex)
    },
    getDataFromApp () {
      if (this.previousSelectedIndex >= 0 && Object.keys(this.parsedShields).length > 0) {
        this.loadedDataFromApp = true
        var self = this
        var shields = this.shields

        window.flutter_inappwebview
          .callHandler('getSelectedValues', '')
          .then(function (result) {
            for (let index = 0; index < shields.length; index++) {
              const shield = shields[index]

              if (shield._id === result.pattern) {
                self.previousSelectedIndex = index
                self.updateSelectedShieldObj(result.colors)
                self.$refs.shieldsCarousel.goTo(self.previousSelectedIndex)

                for (let elementIndex = 0; elementIndex < shields[index].elements.length; elementIndex++) {
                  self.selectedShieldElement = elementIndex
                  self.updateElementColorValue({ hex: result.colors[elementIndex] })
                }
                break
              }
            }
          })
      } else {
        setTimeout(() => {
          this.getDataFromApp()
        }, 1000)
      }
    },
    sendSelectedShieldToApp () {
      var selectedShield = this.parsedShields[this.previousSelectedIndex]
      window.flutter_inappwebview
        .callHandler('saveSelectedValues', selectedShield)
    },
    carouselNext () {
      this.$refs.shieldsCarousel.next()
    },
    carouselPrevious () {
      this.$refs.shieldsCarousel.prev()
    },
    imageErrorHandler (index) {
      this.shields[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.pageContainer {
  background: #F8F8F8;
}

.shield-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 140px;
}

.shield-image {
  width: 100%;
  height: 50%;
}

.text-btn {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
}

.text-secondary {
  color: #005E5D;
  font-size: 16px;
  font-weight: bold;
}

.default-color-picker {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

.element-name {
  font-size: 11px
}

.app-btn {
  border-radius: 11px;
}

.app-btn span {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bolder;
}

.arrowLeftIcon, .arrowRightIcon {
  position: absolute;
  z-index: 99;
}

.arrowLeftIcon {
  left: 0;
}

.arrowRightIcon {
  right: 0;
}
</style>
